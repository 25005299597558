import { useCallback } from "react";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Route } from "react-router-dom";
import ErrorPage from "../ErrorPage";
import Spinner from "../Backdrop";
import moment from "moment";

export const TimeRestrictedRoute = ({ component: Component, ...rest }) => {
  const newCurrentTime = moment().format();

  const startTime = useSelector((state) => state.common.branch.startTime);
  const endTime = useSelector((state) => state.common.branch.endTime);
  const workdate = useSelector((state) => state.common.branch.workdate);

  const [currentTime, setCurrentTime] = useState(null);
  const [loading, setLoading] = useState(true);
  const [validWorkTime, setValidWorkTime] = useState(null);

  console.log(startTime, "startTime");
  console.log(endTime, "endTime");
  console.log(workdate, "workdate");

  //   const getCurrentTime = useCallback(async () => {
  //     try {
  //       const timeRes = await (
  //         await fetch("https://worldtimeapi.org/api/timezone/Africa/Lagos")
  //       ).json();
  //       console.log(timeRes);
  //       setCurrentTime(new Date(timeRes.datetime));
  //     } catch (err) {
  //       console.log(err);
  //       throw err;
  //     }
  //   }, []);

  //   useEffect(() => {
  //     console.log(currentTime);
  //     if (!currentTime) {
  //       // getCurrentTime()
  //     } else {
  //       const day = new Date(workdate).getDay();
  //       // const day = new Date(workdate).getDay();
  //       if (newCurrentTime && startTime && endTime) {
  //         const hour = newCurrentTime.getHours();
  //         const minute = newCurrentTime.getMinutes();
  //         const [startHour, startMinute] = startTime
  //           .split(":")
  //           .map((num) => parseInt(num));
  //         const [endHour, endMinute] = endTime
  //           .split(":")
  //           .map((num) => parseInt(num));

  //         console.log(day >= 1 && day <= 5);
  //         console.log(day);
  //         setValidWorkTime(
  //           day >= 1 &&
  //             day <= 5 &&
  //             (hour >= startHour ||
  //               (hour === startHour && minute >= startMinute)) &&
  //             (hour <= endHour || (hour === endHour && minute < endMinute))
  //         );
  //         setLoading(false);
  //       }
  //     }
  //   }, [newCurrentTime, endTime, startTime, workdate]);

  console.log("newCurrentTime", newCurrentTime);

  useEffect(() => {
    const day = new Date(workdate).getDay();
    // const day = new Date(workdate).getDay();
    const dateTwo = new Date(newCurrentTime);
    if (newCurrentTime && startTime && endTime) {
      const hour = dateTwo.getHours();
      const minute = dateTwo.getMinutes();
      const [startHour, startMinute] = startTime
        .split(":")
        .map((num) => parseInt(num));
      const [endHour, endMinute] = endTime
        .split(":")
        .map((num) => parseInt(num));

      console.log(day >= 1 && day <= 5);
      console.log(day);
      setValidWorkTime(
        day >= 1 &&
          day <= 5 &&
          (hour >= startHour ||
            (hour === startHour && minute >= startMinute)) &&
          (hour <= endHour || (hour === endHour && minute < endMinute))
      );
      setLoading(false);
    }
  }, []);

  if (loading) {
    return <Spinner loading={loading} />;
  } else {
    return (
      <Route
        {...rest}
        render={(props) =>
          validWorkTime ? (
            <Component {...props} />
          ) : (
            <ErrorPage
              message={`Operations are only allowed at ${startTime} and ${endTime} between Mondays and Fridays`}
            />
          )
        }
      />
    );
  }

  // return (
  //     <Route {...rest} render={(props) => (
  //         <ErrorPage message={`An Error Occured (Time)`}/>

  //     )}/>
  // )
};
